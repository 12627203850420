import { t } from "@lingui/macro";
import { Button, ScrollArea, Tabs, TabsContent } from "@reactive-resume/ui";
import { motion } from "framer-motion";
import { useState } from "react";
import { Helmet } from "react-helmet-async";

import { GridView } from "./_layouts/grid";

type Layout = "grid" | "list";

export const ResumesPage = () => {
  const [layout, setLayout] = useState<Layout>("grid");

  return (
    <>
      <Helmet>
        <title>
          {t`Resumes`} - {t`Reactive Resume`}
        </title>
      </Helmet>

      <Tabs
        value={layout}
        onValueChange={(value) => setLayout(value as Layout)}
        className="space-y-4"
      >
        <ScrollArea className="h-[calc(100vh)] lg:h-[calc(100dvh)] pr-12 pb-2">
          <div className="flex items-center justify-between">
            <motion.h1 className="text-3xl font-light tracking-tight">{t`Resumes`}</motion.h1>
            <Button size="md" variant="borderless" className="gap-2" asChild>
              <a
                href="https://app.masterschool.com/campus/notion/How-to-use-the-resume-builder-13e9418319f380d98866f02e7d6d627e"
                target="_blank"
              >
                {t`Resume Builder Guide`}
              </a>
            </Button>
          </div>

          <TabsContent value="grid">
            <GridView />
          </TabsContent>
        </ScrollArea>
      </Tabs>
    </>
  );
};
